html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}



#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.App {
  background-color: #333333;;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.canvasCont {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  canvas {
    width: 100%;
    height: 100%;
  }
}


